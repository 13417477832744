<template>
  <div class="about" v-if="isAboutVisible" @click.stop>
    <button class="close-btn" @click="toggleAbout">×</button>
    <div class="danseuse">
      <img src="/playground_assets/danseuse.png" alt="Nuance Logo" />
    </div>
     <div class="about-text">
      A nuance exists in the subtle in-between, where differences softly converge. A sequence of neighboring intervals, whether in color or sound.<br /><br />
      Nuance gently brings together contrasts, harmonizing where others divide. In a world increasingly fragmented, we strive to unite, more than ever.<br /><br />
      <span style="font-weight: 500">Nuance Radio is our ever-flowing sound library, available 24/7 on this website.</span><br /><br />
    </div>
    <div class="support-buttons">
      <button @click="supportPatreon" class="support-btn">Support on Patreon</button>
      <button @click="donatePaypal" class="support-btn">Donate via Paypal</button>
    </div>
    <div class="socials-container">
      <socials></socials>
    </div>
  </div>
</template>

<script>
import Socials from './socials.vue'

export default {
  components: { Socials },
  data() {
    return {
      isAboutVisible: true // Controls the visibility of the About section
    };
  },
  methods: {
    closeAboutPage() {
      this.isAboutVisible = false; // Hides the about section
    },
    supportPatreon() {
      window.open('https://patreon.com/NuanceRadio', '_blank');
    },
    donatePaypal() {
      window.open('https://www.paypal.com/donate/?hosted_button_id=SXGA8V28PV4LL', '_blank');
    },
  },
  mounted() {
    // Disable any click event listener for navigating back to live page
    document.body.onclick = null;
  }
}
</script>

<style scoped>
.about {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background-color: white;
  padding: 5%;
  margin: 5%; /* Center horizontally */
}
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 2rem;
  cursor: pointer;
}
.danseuse {
  width: 15%;
  background-color: white;
  margin-top: 10px;
  margin-bottom: 10px;
}
.danseuse img {
  width: 100%;
  object-fit: cover;

}
.about-text {
    text-transform: none;
    letter-spacing: normal;
    color: rgb(0, 0, 0);
    font-style: normal;
    font-family: Syne;
    font-weight: 400;
    font-size: 1rem;
    text-align: center;
    background-color: white;
    padding: 10px;
    margin: 20px;
}
.support-buttons {
  margin-bottom: 10px;
  display: flex;
  gap: 10px;
}
.support-btn {
  padding: 10px 20px;
  background-color: black;
  color: white;
  border: none;
  cursor: pointer;
  font-family: Syne;
}
.support-btn:hover {
  background-color: gray;
}
.socials-container {
  margin-top: 20px;
  background-color: white;
  padding: 10px;
  display: flex;
  justify-content: center;
}
@media(max-width: 991px) {
  .socials-container {
    display: none;
  }
}

</style>

